import { MutationHookOptions, MutationResult } from '@apollo/client';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import { Dispatch, SetStateAction, useEffect, useState, useContext } from 'react';
import { filterUndefinedFields } from '@fergdigitalcommerce/fergy-utilities';
import {
	AddToCartInput,
	AddToCartPricedOption,
	Maybe,
	AddProductToCartMutation,
	AddProductToCartMutationVariables,
	CartItemFieldsFragment,
	CartItemsByQuery,
	CartItemsByQueryVariables
} from '../../../client/__generated__/graphql-client-types';
import { DEFAULT_PDP_PRODUCT_STATUS } from '../../constants/product';
import { Selection as SelectionV2 } from '../../types/configuration.types';
import { ProductFamilyContext } from '../../contexts/product-family/product-family.context';
import { AddToCartProductFamily, buildAnalyticsData, trackAddToCart } from '../../helpers/analytics/add-to-cart-analytics.helper';
import {
	calculateTotals,
	CartAddParams,
	createCartAdd,
	createCartAddV2,
	generateRecentCartChanges,
	mergeCartItemChanges,
	RecentCart,
	RecentCartChanges
} from '../../helpers/cart/cart.helper';
import { ADD_TO_CART_BUTTON_TYPE } from '../../helpers/product-helper/add-to-cart-button/add-to-cart-button.helper';
import {
	generateATCAnalyticsData,
	Selection,
	serializeSelection
} from '../../helpers/product-helper/configuration/product-configuration.helper';
import {
	generateATCAnalyticsDataV2,
	serializeSelectionV2
} from '../../helpers/product-helper/configuration-v2/product-configuration.helper';
import { CART_HEADER } from '../../queries/cart/cart-header.queries';
import { ADD_PRODUCT_TO_CART, CART_ITEMS_BY } from '../../queries/cart/add-to-cart.queries';
import { PRODUCT_PAGE } from '../../queries/product/product.queries';
import { ProductFamily } from '../../types/product.types';
export type ProductFamilyInputData = { productFamily: AddToCartProductFamily | Maybe<ProductFamily> } | { parentId: number };

export type AddToCartAnalyticsInputData = {
	variantId: number;
	pageName: string;
	link: string;
	linkName: string;
	quantity?: number;
	recommendedType?: string;
} & ProductFamilyInputData;

export type AddProductToCartParams = {
	variantId: number;
	quantity: number;
	analyticsData: AddToCartAnalyticsInputData;
	pricedOptions?: AddToCartPricedOption[];
	subItems?: AddToCartInput[];
	validate?: (selection: Selection) => boolean;
	validateV2?: (selection: SelectionV2) => boolean;
};

export type AddToCart = (params: AddProductToCartParams) => Promise<AddProductToCartMutation | void>;

export type AddToCartHookResult = {
	addToCart: AddToCart;
	status: MutationResult<AddProductToCartMutation>;
};

/**
 * Add To Cart Action Hook with optional params that customize add to cart mutation and other behavior.
 */
export const useAddToCartAction = (
	hookOptions?: MutationHookOptions<AddProductToCartMutation, AddProductToCartMutationVariables>
): AddToCartHookResult => {
	hookOptions = hookOptions ? hookOptions : { refetchQueries: [{ query: CART_HEADER }], awaitRefetchQueries: true };
	const [addProductToCart, status] = useMutation<AddProductToCartMutation, AddProductToCartMutationVariables>(
		ADD_PRODUCT_TO_CART,
		hookOptions
	);

	const client = useApolloClient();

	// On some pages you won't have the productFamily data when the Add To Cart button is clicked
	// i.e. /guided-shopping/smart-home/
	function getProductFamily(analyticsData: AddToCartAnalyticsInputData): Promise<AddToCartProductFamily | null> {
		if ('productFamily' in analyticsData) {
			return Promise.resolve(analyticsData.productFamily);
		} else if ('parentId' in analyticsData) {
			// useLazyQuery does not return a Promise and you would need to use the onCompleted option
			// The useApolloClient hook is cleaner to use here
			return client
				.query({
					query: PRODUCT_PAGE,
					variables: { id: analyticsData.parentId, productStatus: DEFAULT_PDP_PRODUCT_STATUS }
				})
				.then(({ data }) => data.productFamily)
				.catch((error) => {
					logError(error);
					return error;
				});
		} else {
			return Promise.reject(new Error('productFamily or familyId not available'));
		}
	}

	function addToCart(params: AddProductToCartParams): Promise<AddProductToCartMutation> {
		const { variantId, quantity, pricedOptions, analyticsData, subItems } = params;
		// Mutation data is needed for a callback in the add-to-cart button
		// wrapped the promise chain with another promise to return the data
		return new Promise((resolve, reject) => {
			let successPayload: AddProductToCartMutation;
			// This promise creates the mutation
			void addProductToCart({
				variables: { input: filterUndefinedFields({ variantId, quantity, pricedOptions }) as AddToCartInput, subItems }
			}) // Checking to see if data is returned then assign it to payloadSuccess, else handles error
				.then(({ data }) => {
					if (!data?.addProductToCart) {
						throw new Error('Add to cart failed unexpectedly');
					} else {
						successPayload = data;
					}
				}) // grabbing product family for analytics
				.then(() =>
					getProductFamily(analyticsData).catch((error) => {
						throw error;
					})
				) // create tracking data
				.then((productFamily) => {
					const { link, pageName, recommendedType } = analyticsData;
					const cart = successPayload.addProductToCart?.cart;
					const trackingData =
						productFamily?.id && cart
							? buildAnalyticsData(variantId, quantity, link, pageName, productFamily, cart, recommendedType)
							: undefined;
					return trackAddToCart(trackingData);
				}) // return successPayload
				.then(() => {
					resolve(successPayload);
				})
				.catch((error) => {
					logError(error);
					reject(error);
				});
		});
	}

	return { addToCart, status };
};

/**
 * Similar to useAddToCartAction but this hook will first validate configurable selection (via the validate parameter) if it is provided
 * and will re-use the useAddToCartAction internally to perform the same operation
 */
export const useCustomAddProductToCart = ({
	variantId,
	quantity,
	analyticsData,
	validate
}: Omit<Partial<AddProductToCartParams>, 'pricedOptions'>): [
	(current: Selection, addToCartButtonType?: ADD_TO_CART_BUTTON_TYPE) => Promise<AddProductToCartMutation | void>,
	() => void,
	MutationResult<AddProductToCartMutation>
] => {
	const { addToCart, status } = useAddToCartAction();
	const [addToCartStatus, setAddToCartStatus] = useState(status);
	const { productFamily } = useContext(ProductFamilyContext);
	// Workaround to reset mutation result: https://github.com/apollographql/apollo-feature-requests/issues/170
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setAddToCartStatus(status), [status.loading]);
	return [
		async (current, addToCartButtonType) => {
			if (variantId) {
				if (validate && !validate(current)) {
					return;
				}
				const qty = quantity || 1;
				const atcAnalyticsData = generateATCAnalyticsData(variantId, qty, productFamily, analyticsData, addToCartButtonType);
				const { pricedOptions, subItems } = serializeSelection(current);
				const payload = { variantId, quantity: qty, pricedOptions, analyticsData: atcAnalyticsData, subItems };
				return addToCart(payload);
			}
		},
		() => setAddToCartStatus((prev) => ({ ...prev, data: undefined })),
		addToCartStatus
	];
};

/**
 * Similar to useAddToCartAction but this hook will first validate configurable selection (via the validate parameter) if it is provided
 * and will re-use the useAddToCartAction internally to perform the same operation
 * this hook is meant for V2 of complex products and should replace the other hook when successful
 */
export const useCustomAddProductToCartV2 = ({
	variantId,
	quantity,
	analyticsData,
	validateV2
}: Omit<Partial<AddProductToCartParams>, 'pricedOptions'>): [
	(current: SelectionV2, addToCartButtonType?: ADD_TO_CART_BUTTON_TYPE) => Promise<AddProductToCartMutation | void>,
	() => void,
	MutationResult<AddProductToCartMutation>
] => {
	const { addToCart, status } = useAddToCartAction();
	const [addToCartStatus, setAddToCartStatus] = useState(status);
	const { productFamily } = useContext(ProductFamilyContext);
	// Workaround to reset mutation result: https://github.com/apollographql/apollo-feature-requests/issues/170
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setAddToCartStatus(status), [status.loading]);
	return [
		async (current, addToCartButtonType) => {
			if (variantId) {
				if (validateV2 && !validateV2(current)) {
					return;
				}
				const qty = quantity || 1;
				const atcAnalyticsData = generateATCAnalyticsDataV2(variantId, qty, productFamily, analyticsData, addToCartButtonType);
				const { pricedOptions, subItems } = serializeSelectionV2(current);
				const payload = { variantId, quantity: qty, pricedOptions, analyticsData: atcAnalyticsData, subItems };
				return addToCart(payload);
			}
		},
		() => setAddToCartStatus((prev) => ({ ...prev, data: undefined })),
		addToCartStatus
	];
};

type UseCartRecentlyUpdatedResult = {
	cartItems: CartItemFieldsFragment[];
	recentCartChanges: RecentCartChanges;
	setSelection: Dispatch<SetStateAction<Selection>>;
	loadCartItemsBy: (cartItemIds: number[], selection?: Selection) => void;
	addRecommendedToCart: (params: AddProductToCartParams) => Promise<AddProductToCartMutation | void>;
	addRecommendedToCartIsLoading: boolean;
	resetRecentCartChanges: (initialSnapshot?: CartAddParams) => void;
};

/**
 * Similar to useAddToCartAction but this hook maintains a list of recently products added to the cart.
 * The useRecentCartUpdate hook will be capturing snapshots of the cart using an initial selection as the starting point.
 * Later, on each call to the add to cart mutation for recommended products will keep recording snapshots and updating
 * the information accordingly into the recent cart changes.
 */
export const useCartRecentlyUpdated = (initialSelection: Selection): UseCartRecentlyUpdatedResult => {
	const { changes, capture, reset } = useRecentCartUpdate();
	const [queryCartItemsBy, cartItemsStatus] = useLazyQuery<CartItemsByQuery, CartItemsByQueryVariables>(CART_ITEMS_BY);
	const [selection, setSelection] = useState<Selection>(initialSelection);
	const { addToCart, status } = useAddToCartAction({ refetchQueries: undefined }); // No ReFetching needed.
	const newCartItems = cartItemsStatus.data?.cartItemsBy;
	const loadCartItemsBy = (newItemIds: number[]) => {
		const updatedCartItemIds = [...changes.items.map(({ id }) => id), ...newItemIds];
		const cartItemIds = updatedCartItemIds.filter((id, ix) => updatedCartItemIds.indexOf(id) === ix);
		void queryCartItemsBy({ variables: { filtersInput: { cartItemIds } } });
	};
	const addRecommendedToCart = (params: AddProductToCartParams): Promise<AddProductToCartMutation | void> => {
		return addToCart(params)
			.then((result: AddProductToCartMutation) => {
				return result?.addProductToCart ? loadCartItemsBy(result.addProductToCart.newItemIds) : void 0;
			})
			.catch(logError);
	};

	// Captures recent card changes when cart items changes
	useEffect(() => {
		if (cartItemsStatus.error) {
			logError(cartItemsStatus.error);
		} else if (newCartItems) {
			const newCartAdd = createCartAdd(selection, newCartItems);
			return newCartAdd ? capture(newCartAdd) : void 0;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newCartItems]);

	return {
		cartItems: newCartItems || [],
		recentCartChanges: changes,
		setSelection,
		loadCartItemsBy,
		addRecommendedToCart,
		addRecommendedToCartIsLoading: status.loading,
		resetRecentCartChanges: reset
	};
};

type UseCartRecentlyUpdatedResultV2 = {
	cartItems: CartItemFieldsFragment[];
	previousCartItems: CartItemFieldsFragment[];
	cartItemsLoading: boolean;
	recentCartChanges: RecentCartChanges;
	setSelection: Dispatch<SetStateAction<SelectionV2>>;
	loadCartItemsBy: (cartItemIds: number[], selection?: Selection) => void;
	addRecommendedToCart: (params: AddProductToCartParams) => Promise<AddProductToCartMutation | void>;
	addRecommendedToCartIsLoading: boolean;
	resetRecentCartChanges: (initialSnapshot?: CartAddParams) => void;
};

/**
 * Similar to useAddToCartAction but this hook maintains a list of recently added products to the cart.
 * The useRecentCartUpdate hook will be capturing snapshots of the cart using an initial selection as the starting point.
 * Later, on each call to the add to cart mutation for recommended products will keep recording snapshots and updating
 * the information accordingly into the recent cart changes.
 */
export const useCartRecentlyUpdatedV2 = (initialSelection: SelectionV2): UseCartRecentlyUpdatedResultV2 => {
	const { changes, capture, reset } = useRecentCartUpdate();
	const [queryCartItemsBy, cartItemsStatus] = useLazyQuery<CartItemsByQuery, CartItemsByQueryVariables>(CART_ITEMS_BY);
	const [selection, setSelection] = useState<SelectionV2>(initialSelection);
	const { addToCart, status } = useAddToCartAction({ refetchQueries: undefined }); // No ReFetching needed.
	const newCartItems = cartItemsStatus.data?.cartItemsBy;

	// eslint-disable-next-line sonarjs/no-identical-functions
	const loadCartItemsBy = (newItemIds: number[]) => {
		const updatedCartItemIds = [...changes.items.map(({ id }) => id), ...newItemIds];
		const cartItemIds = updatedCartItemIds.filter((id, ix) => updatedCartItemIds.indexOf(id) === ix);
		void queryCartItemsBy({ variables: { filtersInput: { cartItemIds } } });
	};

	// eslint-disable-next-line sonarjs/no-identical-functions
	const addRecommendedToCart = (params: AddProductToCartParams): Promise<AddProductToCartMutation | void> => {
		return addToCart(params)
			.then((result: AddProductToCartMutation) => {
				return result?.addProductToCart ? loadCartItemsBy(result.addProductToCart.newItemIds) : void 0;
			})
			.catch(logError);
	};

	// Captures recent card changes when cart items changes
	useEffect(() => {
		if (cartItemsStatus.error) {
			logError(cartItemsStatus.error);
		} else if (newCartItems) {
			const newCartAdd = createCartAddV2(selection, newCartItems);
			return newCartAdd ? capture(newCartAdd) : void 0;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newCartItems]);

	return {
		cartItems: newCartItems || [],
		previousCartItems: cartItemsStatus.previousData?.cartItemsBy || [],
		cartItemsLoading: cartItemsStatus.loading,
		recentCartChanges: changes,
		setSelection,
		loadCartItemsBy,
		addRecommendedToCart,
		addRecommendedToCartIsLoading: status.loading,
		resetRecentCartChanges: reset
	};
};

/**
 * Hook that takes an Add To Cart operation as a starting point to track the most recent products added to the cart.
 * Subsequent calls to the 2nd function exposed by this hook, referred as 'capture', will record a new add to cart
 * operation to update the recent cart changes currently tracked based on the new snapshot being passed and the previous state captured.
 * Finally, the third function provides a quick way to reset the recent cart changes back to zero.
 * Optionally, you could pass a new add to cart snapshot as the starting point for convenience purposes.
 */
export const useRecentCartUpdate = (initialSnapshot?: CartAddParams): RecentCart => {
	const [changes, setChanges] = useState<RecentCartChanges>(generateRecentCartChanges(initialSnapshot));
	return {
		changes,
		capture: (nextSnapshot: CartAddParams) => {
			setChanges((prevRecentCartChanges) => {
				const newRecentCartChanges = generateRecentCartChanges(nextSnapshot);
				const updatedItems = mergeCartItemChanges(newRecentCartChanges, prevRecentCartChanges);
				return { ...prevRecentCartChanges, ...calculateTotals(updatedItems), items: updatedItems };
			});
		},
		reset: (resetSnapshot?: CartAddParams) => {
			setChanges(generateRecentCartChanges(resetSnapshot));
		}
	};
};
