import React, { Component, PropsWithChildren } from 'react';
import { ProductFamily } from '../../types/product.types';

/*
 * The purpose of this context is to avoid prop drilling.
 * For instance, on finish change we need to send product family data to analytics.
 */

type ProductFamilyContextState = {
	productFamily: ProductFamily;
};

/** in practice the productFamily will always exist */
const initialState: ProductFamilyContextState = {} as ProductFamilyContextState;

export const ProductFamilyContext = React.createContext<ProductFamilyContextState>(initialState);

export class ProductFamilyContextProvider extends Component<PropsWithChildren<ProductFamily>, ProductFamilyContextState> {
	readonly state: ProductFamilyContextState = {
		productFamily: this.props
	};
	render() {
		return <ProductFamilyContext.Provider value={this.state}>{this.props.children}</ProductFamilyContext.Provider>;
	}
}
